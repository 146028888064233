"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cormorantGaramondMedium = void 0;
exports.cormorantGaramondMedium = {
    familyName: "Cormorant Garamond",
    weight: "500",
    style: "normal",
    ttfName: "CormorantGaramond-Medium",
    ttfFile: "cormorant-garamond-medium.ttf",
    woff2File: "cormorant-garamond-medium.woff2",
};
