"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cormorantGaramondBold = void 0;
exports.cormorantGaramondBold = {
    familyName: "Cormorant Garamond",
    weight: "700",
    style: "normal",
    ttfName: "CormorantGaramond-Bold",
    ttfFile: "cormorant-garamond-bold.ttf",
    woff2File: "cormorant-garamond-bold.woff2",
};
