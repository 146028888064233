"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.cormorantGaramond = void 0;
const cormorant_garamond_bold_1 = require("../fonts/cormorant-garamond-bold");
const cormorant_garamond_medium_1 = require("../fonts/cormorant-garamond-medium");
exports.cormorantGaramond = {
    headingXLarge: {
        font: cormorant_garamond_bold_1.cormorantGaramondBold,
        fontSize: 31,
        lineHeight: 38,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingLarge: {
        font: cormorant_garamond_bold_1.cormorantGaramondBold,
        fontSize: 25,
        lineHeight: 32,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingMedium: {
        font: cormorant_garamond_bold_1.cormorantGaramondBold,
        fontSize: 21,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingSmall: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    headingXSmall: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 20,
        letterSpacing: 1.8,
        textTransform: "uppercase",
    },
    body: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 22,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    strong: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    caption: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 18,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    captionStrong: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 18,
        letterSpacing: undefined,
        textTransform: undefined,
    },
    button: {
        font: cormorant_garamond_medium_1.cormorantGaramondMedium,
        fontSize: 18,
        lineHeight: 20,
        letterSpacing: undefined,
        textTransform: undefined,
    },
};
